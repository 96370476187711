<!--
 * @Author: 侯怀烨
 * @Date: 2020-12-11 10:08:03
 * @LastEditTime: 2021-04-06 17:42:22
 * @LastEditors: Please set LastEditors
 * @Description: 新建预存款单据
 * @FilePath: \sd-vue-admin\src\views\project\movablepin\predeposit\depositreceipt\components\NewDocuments.vue
-->
<template>
  <div>
    <el-button type="primary" @click="newshow = true">添加客户预存款</el-button>
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="添加客户预存款"
      :visible.sync="newshow"
      width="70%"
      center
    >
      <div>
        <el-form ref="form" :model="form" label-width="100px">
          <el-form-item label="预存活动：" prop="deposit_id">
            <el-select
              v-model="form.deposit_id"
              placeholder="请选择预存款活动"
              style="width: 200px"
              :disabled="form.newHd"
              @change="select"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <el-checkbox v-model="form.newHd" style="margin-left: 30px">
              自定义预存款
            </el-checkbox>
          </el-form-item>
          <el-form-item label="业务员：" prop="staff_id">
            <el-select
              v-model="form.staff_id"
              placeholder="请选择业务员"
              style="width: 200px"
            >
              <el-option
                v-for="item in ywlist"
                :key="item.user_id"
                :label="item.user_name"
                :value="item.user_id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="客户名称：" prop="name">
            <!-- <el-autocomplete
              v-model="form.name"
              style="width: 200px"
              placeholder="客户名称，助记码"
              suffix-icon="el-icon-search"
              value-key="cust_name"
              :fetch-suggestions="querySearchAsync"
              @select="handleSelect"
            ></el-autocomplete> -->
            <client-search
              ref="clientSearch"
              :popper-class="'selectDC'"
              style="width: 210px"
              @select-val-alldata="clientSelect"
            ></client-search>
            <!-- <h5>联系人：</h5> -->
            <span style="margin-left: 30px">
              联系人：{{ form.boss }}{{ form.mobile }}
            </span>
          </el-form-item>
          <el-form-item label="地址：" prop="address">
            <span>{{ form.address }}</span>
          </el-form-item>
          <el-form-item label="备注：" prop="note">
            <el-input
              v-model="form.note"
              style="width: 250px"
              placeholder="可以输入备注"
            ></el-input>
          </el-form-item>
          <!-- 预选方案的table -->
          <el-form-item label="预存方案：" prop="sche_list">
            <el-table
              v-show="!form.newHd"
              stripe
              border
              style="width: 75%"
              :data="form.sche_list"
            >
              <el-table-column width="60">
                <template #default="{ row }">
                  <el-radio
                    v-model="form.fangaid"
                    :label="row.id"
                    :name="row.id"
                    @change="change(row)"
                  >
                    <i></i>
                  </el-radio>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="方案"
                width="80"
                type="index"
              ></el-table-column>
              <el-table-column
                align="center"
                label="预存金额"
                width="auto"
                :prop="form.sche_list.total_amount"
              >
                <template slot-scope="scope">
                  <el-input
                    v-model="scope.row.total_amount"
                    placeholder="请输入预存金额"
                    @input="addrow(scope.$index)"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="赠送金额"
                width="auto"
                :prop="form.sche_list.gift_amount"
              >
                <template slot-scope="scope">
                  <el-input
                    v-model="scope.row.gift_amount"
                    placeholder="请输入赠送金额"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="协议赠品"
                width="auto"
                :prop="form.sche_list.give_data"
              >
                <template slot-scope="scope">
                  <span
                    style="
                      padding: 0px 10px;
                      text-decoration: underline;
                      cursor: pointer;
                    "
                    @click="zpc(form.sche_list[scope.$index].give_data)"
                  >
                    {{ form.sche_list[scope.$index].give_data.length }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="限定商品"
                width="auto"
                :prop="form.sche_list.goods_data"
              >
                <template slot-scope="scope">
                  <span
                    style="
                      padding: 0px 10px;
                      text-decoration: underline;
                      cursor: pointer;
                    "
                    @click="
                      shangpin(
                        form.sche_list[scope.$index].goods_data,
                        form.sche_list[scope.$index].brand_data
                      )
                    "
                  >
                    {{ form.sche_list[scope.$index].goods_data.length }}
                  </span>
                </template>
              </el-table-column>
            </el-table>
            <div v-show="form.newHd">
              <customize
                ref="xiandingshangpin"
                @list="tablelis"
                @sangpin="sangpin"
              ></customize>
            </div>
            <el-row>
              <el-col :span="24">
                <el-switch
                  v-model="form.repeat_status"
                  :disabled="!form.newHd"
                  :active-value="'1'"
                  :inactive-value="'0'"
                  active-text="活动可重复参加"
                ></el-switch>
                <br />
                <el-switch
                  v-model="form.price_status"
                  :disabled="!form.newHd"
                  :active-value="'1'"
                  :inactive-value="'0'"
                  active-text="下单时业务员可修改价格"
                ></el-switch>

                <br />
                <el-switch
                  v-model="form.exceed_status"
                  :disabled="!form.newHd"
                  :active-value="'1'"
                  :inactive-value="'0'"
                  active-text="允许超额下单：勾选后，客户可超出预存款单据余额下单，超出部分计入应收欠款"
                ></el-switch>

                <br />
                <!-- <el-switch
                  v-model="form.gift_status"
                  :disabled="!form.newHd"
                  active-text="允许使用预存款购买常规赠品"
                  :active-value="'1'"
                  :inactive-value="'0'"
                ></el-switch>

                <br /> -->
                <el-radio
                  v-model="form.check_status"
                  :disabled="!form.newHd"
                  label="1"
                >
                  必须审核：所有预存款协议，必须后台审核后方可生效
                </el-radio>
                <el-radio
                  v-model="form.check_status"
                  :disabled="!form.newHd"
                  label="0"
                >
                  异常审核：勾选后，业务员修改协议内容，需后台审核后方可生效
                </el-radio>
              </el-col>
            </el-row>
            <el-row>
              <span>赠品赠送条件：</span>
              <el-radio-group v-model="form.gift_goods_type">
                <el-radio
                  v-for="city in citiesing"
                  :key="city.id"
                  :disabled="!form.newHd"
                  :label="city.id"
                >
                  {{ city.name }}
                </el-radio>
              </el-radio-group>
            </el-row>
          </el-form-item>
          <el-form-item
            v-show="form.newHd"
            label="签单金额："
            prop="total_amount"
          >
            <el-input
              v-model="form.total_amount"
              type="number"
              placeholder="请输入签单金额"
              style="width: 150px"
              min="0"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-show="form.newHd"
            label="赠送金额："
            prop="gift_amount"
          >
            <el-input
              v-model="form.gift_amount"
              type="number"
              placeholder="请输入签单金额"
              style="width: 150px"
              min="0"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-for="(list, index) in form.pay_data"
            :key="list.id"
            label="收款金额："
            prop="pay_data"
          >
            <el-input
              v-model="list.money"
              type="number"
              placeholder="请输入收款金额"
              style="width: 150px; margin-right: 10px"
              min="0"
              @blur="moneytrue"
            ></el-input>
            <el-select v-model="list.pay_type" style="width: 120px">
              <el-option
                v-for="lists in paytype"
                :key="lists.id"
                placeholder="请选择"
                :label="lists.name"
                :value="lists.id"
              ></el-option>
            </el-select>
            <i
              v-if="index == 0"
              class="el-icon-circle-plus addbtn"
              @click="addInput"
            ></i>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="newshow = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    <collections-sperclass ref="superclass"></collections-sperclass>
  </div>
</template>

<script>
  import customize from './customize.vue'
  import { getClientSelect } from '@/api/saleOrder'
  import { postAction } from '@/api/Employee'
  import CollectionsSperclass from './CollectionSuperclass'
  import clientSearch from '@/baseComponents/clientSearch'
  export default {
    components: {
      customize,
      CollectionsSperclass,
      clientSearch,
    },
    data() {
      return {
        newshow: false,
        options: [],
        ywlist: [],
        paytype: [],
        form: {
          check_status: '',
          deposit_id: '',
          newHd: false,
          staff_id: '',
          total_amount: '',
          gift_amount: '',
          pay_data: [
            {
              pay_type: '',
              pay_name: '',
              money: '',
            },
          ],
          paymoney: '',
          boss: '',
          mobile: '',
          cust_id: '',
          name: '',
          address: '',
          note: '',
          sche_list: [],
          repeat_status: '0',
          price_status: '0',
          exceed_status: '0',
          gift_status: '0',
          gift_goods_type: '',
          fanga: [], //单选的方案
          fangaid: '',
        },
        checkedCities: [],
        citiesing: [
          { name: '无限制', id: '1' },
          { name: '付款完成可下赠品', id: '2' },
          { name: '消费完后可下赠品', id: '3' },
        ],
        url: {
          type: '/promoteAdmin/deposit-order/pay-type',
          oldVal: '/promoteAdmin/deposit-order/join',
          yclist: '/promoteAdmin/deposit',
        },
        //协议赠品
        give_data: [],
        brand_data: [],
        goods_data: [],
        yw: [],
      }
    },
    watch: {
      newshow(val) {
        if (!val) {
          this.form.newHd = false
          this.form.check_status = ''
          this.form.deposit_id = ''
          this.form.staff_id = ''
          this.form.total_amount = ''
          this.form.gift_amount = ''
          this.form.pay_data = [
            {
              pay_type: '',
              pay_name: '',
              money: '',
            },
          ]
          this.form.paymoney = ''
          this.form.boss = ''
          this.form.mobile = ''
          this.form.cust_id = ''
          this.form.name = ''
          this.form.address = ''
          this.form.note = ''
          this.form.sche_list = []
          this.form.repeat_status = '0'
          this.form.price_status = '0'
          this.form.exceed_status = '0'
          this.form.gift_status = '0'
          this.form.gift_goods_type = ''
          this.form.fanga = [] //单选的方案
          this.form.fangaid = ''
          this.$refs.xiandingshangpin.$refs.ding.kong()
        } else {
          this.activeSelect()
        }
      },
      'form.newHd'(val, oldVal) {
        console.log('currentVal', val, oldVal)
        if (val == true) {
          this.handlerSalesman('')
          this.form.price_status = '1'
        } else {
          this.ywlist = []
          this.form.price_status = '0'
        }
        console.log(this.ywlist, '所有人')
        this.form.check_status = ''
        this.form.deposit_id = ''
        this.form.staff_id = ''
        this.form.total_amount = ''
        this.form.gift_amount = ''
        this.form.pay_data = [
          {
            pay_type: '',
            pay_name: '',
            money: '',
          },
        ]
        this.form.paymoney = ''
        this.form.boss = ''
        this.form.mobile = ''
        this.form.cust_id = ''
        this.form.name = ''
        this.form.address = ''
        this.form.note = ''
        this.form.sche_list = []
        this.form.repeat_status = '0'
        this.form.exceed_status = '0'
        this.form.gift_status = '0'
        this.form.gift_goods_type = ''
        this.form.fanga = [] //单选的方案
        this.form.fangaid = ''
      },
      'form.name'(val, oldVal) {
        console.log(val, oldVal, '客户名称')
        if (val == '') {
          this.form.boss = ''
          this.form.mobile = ''
          this.form.address = ''
        }
      },
    },
    mounted() {
      this.activeSelect()
      this.handlertype()
    },
    methods: {
      // 预存活动下拉
      activeSelect() {
        postAction(this.url.yclist, { pageSize: -1, activity_status: 1 })
          .then((res) => {
            console.log(res, '')
            this.options = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //收款金额校验效果
      moneytrue() {
        var sum = 0
        this.form.pay_data.forEach((item) => {
          sum += Number(item.money)
        })
        console.log(sum, '')
        if (sum > this.form.total_amount && this.form.newHd) {
          this.$notify({
            title: '警告',
            message: '总收款金额不能大于签单金额请改正后提交',
            offset: 100,
          })
        }
      },

      //协议赠品
      tablelis(row) {
        console.log(row, '协议赠品')
        this.give_data = row

        console.log(this.form.sche_list)
      },
      sangpin(req, rej) {
        console.log(req, rej, '')
        this.brand_data = rej
        this.goods_data = req
      },
      submit() {
        if (this.form.newHd == true) {
          this.handlerCustomize()
        } else {
          var datas = new Object()
          datas.staff_id = this.form.staff_id
          datas.info_id = this.form.deposit_id
          if (this.form.fangaid == '') {
            this.$message({
              message: '请选择一种方案',
              type: 'warning',
            })
            return
          } else {
            datas.detail_id = this.form.fangaid
          }

          datas.cust_id = this.form.cust_id
          console.log(this.form.sche_list, '这个走没走')
          if (this.form.sche_list.length < 0) {
            this.$message({
              message: '请选择一种方案',
              type: 'warning',
            })
            return
          } else {
            this.form.sche_list.forEach((list) => {
              if (list.id == this.form.fangaid) {
                console.log(list, '方案')
                datas.total_amount = list.total_amount
                datas.gift_amount = list.gift_amount
                datas.give_data = JSON.parse(JSON.stringify(list.give_data))
              }
            })
          }

          /*
           * @Description后来加的这个包装工具
           * @Date 2021/9/4
           * */
          datas.give_data = datas.give_data.map((list) => {
            return {
              goods_id: list.goods_id,
              unit_data: [
                {
                  unit_id: list.unit_id,
                  goods_num: list.goods_num,
                },
              ],
            }
          })
          console.log(datas.give_data, '修改协议赠票')
          datas.pay_data = JSON.parse(JSON.stringify(this.form.pay_data))
          datas.is_edi = 0
          datas.give_data = JSON.stringify(datas.give_data)
          datas.pay_data = JSON.stringify(datas.pay_data)
          postAction(this.url.oldVal, datas)
            .then((res) => {
              console.log(res, '')
              this.$message({
                message: res.msg,
                type: 'success',
              })
              this.newshow = false
              this.$emit('getlist')
            })
            .catch((err) => {
              console.log(err, '')
            })
        }
      },
      //自定义方案时走这个
      handlerCustomize() {
        var data = new Object()
        data.cust_id = this.form.cust_id
        data.staff_id = this.form.staff_id
        data.total_amount = this.form.total_amount
        data.gift_amount = this.form.gift_amount
        data.note = this.form.note
        data.gift_goods_type = this.form.gift_goods_type
        data.gift_status = this.form.gift_status
        data.price_status = this.form.price_status
        data.exceed_status = this.form.exceed_status
        data.pay_data = JSON.parse(JSON.stringify(this.form.pay_data))
        data.goods_data = JSON.parse(JSON.stringify(this.goods_data))
        data.brand_data = JSON.parse(JSON.stringify(this.brand_data))
        data.give_data = JSON.parse(JSON.stringify(this.give_data))
        if (data.pay_data.length > 0) {
          data.pay_data = JSON.stringify(data.pay_data)
        }
        if (data.goods_data.length > 0) {
          data.goods_data = JSON.stringify(data.goods_data)
        }
        if (data.brand_data.length > 0) {
          data.brand_data = JSON.stringify(data.brand_data)
        }
        if (data.give_data.length > 0) {
          data.give_data = JSON.stringify(data.give_data)
        }
        console.log(data, '自定义方案数据')
        postAction('/promoteAdmin/deposit-order/create', data)
          .then((res) => {
            console.log(res, '自定义方案成功')
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.newshow = false
            this.$emit('getlist')
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //添加支付方式
      addInput() {
        this.form.pay_data.push({
          pay_type: '',
          pay_name: '',
          money: '',
        })
      },
      //支付方式
      handlertype() {
        postAction(this.url.type, {})
          .then((res) => {
            console.log(res, '')
            this.paytype = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      // 单选框绑定值变化时触发的事件（选中的 Radio label 值）
      change(row) {
        console.log(row, '')
        this.form.fangaid = row.id
        this.form.fanga = row
      },
      shangpin(row, dato) {
        this.$refs.superclass.sp = true
        this.$refs.superclass.spdata = row
        this.$refs.superclass.treelist = dato
        // console.log(this.$refs.superclass.spdata)
      },
      zpc(row) {
        this.$refs.superclass.zp = true
        this.$refs.superclass.zpdata = row
        console.log(this.$refs.superclass.zpdata)
      },
      select(valid) {
        console.log(valid, '')
        postAction('/promoteAdmin/deposit/detail', { id: valid })
          .then((res) => {
            console.log(res, '已有方案详情')
            this.form.newHd = false
            this.form.note = res.data.desc
            this.form.sche_list = res.data.sche_list
            this.form.repeat_status = res.data.repeat_status
            this.form.check_status = res.data.check_status
            this.form.price_status = res.data.price_status
            this.form.exceed_status = res.data.exceed_status
            this.form.gift_status = res.data.gift_status
            this.form.gift_goods_type = res.data.gift_goods_type
            if (this.form.sche_list.length > 0) {
              this.form.fangaid = this.form.sche_list[0].id
            }
            this.handlerSalesman(res.data.limit_user)
          })
          .catch((err) => {
            console.log('err', '')
          })
      },
      handlerSalesman(limit_user) {
        postAction('baseAdmin/common/user-list', { limit_user: limit_user })
          .then((res) => {
            console.log(res, '限定业务员')
            this.form.staff_id = ''
            this.ywlist = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      async fetchData(ky = '') {
        let { data, code, msg } = await getClientSelect({
          pageNo: 1,
          pageSize: -1,
          keyword: ky,
          is_closed: 0,
        })
        console.log('123')
        console.log(data)
        if (code == 200) {
          this.res = data
          return data
        } else {
          this.$message.error(msg)
        }
      },
      handleSelect(val) {
        console.log(val, this.form.cust_id, '')
        this.form.cust_id = val.id
        this.form.address = val.address
        this.form.boss = val.boss
        this.form.mobile = val.mobile
      },
      clientSelect(val) {
        console.log('val', val)
        this.form.cust_id = val.id
        this.form.address = val.address
        this.form.boss = val.boss
        this.form.mobile = val.mobile
      },
      querySearchAsync(queryString, callback) {
        if (queryString == '') {
          cb([])
        } else {
          this.fetchData(queryString).then((val) => {
            console.log(val)
            callback(val)
          })
        }
      },
    },
  }
</script>

<style>
  .addbtn {
    position: absolute;
    top: 0;
    /* right: -50px; */
    margin-left: 15px;
    font-size: 32px;
    color: #84bc39;
  }
</style>
