<!-- 预存款单据审核 -->
<template>
  <div class="orderTest-container">
    <el-dialog
      top="5vh"
      :close-on-click-modal="false"
      :modal="false"
      center
      title="预存款单审核"
      :visible.sync="showreview"
    >
      <div>
        <el-form ref="ruleform" :model="form" inline label-width="120px">
          <el-form-item label="单号：" prop="">
            <el-input
              v-model="form.bill_code"
              disabled
              placeholder="单号"
            ></el-input>
          </el-form-item>
          <el-form-item label="签单：">
            <el-input
              v-model="form.create_at"
              disabled
              placeholder="签单"
            ></el-input>
          </el-form-item>
          <el-form-item label="客户名称：">
            <el-input
              v-model="form.cust_name"
              disabled
              placeholder="客户名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人：">
            <el-input
              v-model="form.boss"
              disabled
              placeholder="联系人"
            ></el-input>
          </el-form-item>
          <el-form-item label="地址：">
            <el-input
              v-model="form.address"
              disabled
              placeholder="地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="活动名称：">
            <el-input
              v-model="form.name"
              disabled
              placeholder="活动名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="业务员：">
            <el-input
              v-model="form.staff_name"
              disabled
              placeholder="业务员"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注：">
            <el-input
              v-model="form.note"
              disabled
              placeholder="备注"
            ></el-input>
          </el-form-item>
          <br />
          <el-form-item label="预存方案："></el-form-item>
          <el-table stripe :data="form.sche_list">
            <el-table-column
              label="预存金额"
              prop="total_amount"
              align="center"
            ></el-table-column>
            <el-table-column
              label="赠送金额"
              prop="gift_amount"
              align="center"
            ></el-table-column>
            <el-table-column label="协议赠品" align="center">
              <template #default="{ row }">
                <span class="textstaly" @click="handlergive(row)">
                  {{ form.give_list_front.length }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="限定商品" align="center">
              <template>
                <span class="textstaly" @click="handlergoodslist">
                  {{ form.goods_list_front.length }}
                </span>
              </template>
            </el-table-column>
          </el-table>
          <el-form-item>
            <el-switch
              v-model="form.price_status"
              :active-value="'1'"
              :inactive-value="'0'"
              active-text="下单时业务员可修改价格"
            ></el-switch>
          </el-form-item>
          <br />
          <el-form-item>
            <el-switch
              v-model="form.exceed_status"
              :active-value="'1'"
              :inactive-value="'0'"
              active-text="允许超额下单：客户可超出预存款单据余额下单，超出部分计入应收欠款"
            ></el-switch>
          </el-form-item>
          <br />
          <!-- <el-form-item>
            <el-switch
              v-model="form.gift_status"
              :active-value="'1'"
              :inactive-value="'0'"
              active-text="允许使用预存款购买常规赠品"
            ></el-switch>
          </el-form-item> -->
          <el-form-item label="赠品赠送条件：">
            <el-radio-group v-model="form.gift_goods_type">
              <el-radio label="1">无限制</el-radio>
              <el-radio label="2">付款完成可下赠品</el-radio>
              <el-radio label="3">消费完后可下赠品</el-radio>
            </el-radio-group>
          </el-form-item>
          <br />
          <el-form-item label="签单金额：">
            <el-input
              v-model="form.total_amount"
              placeholder="签单金额"
            ></el-input>
          </el-form-item>
          <el-form-item label="赠送金额：">
            <el-input
              v-model="form.gift_amount"
              placeholder="赠送金额"
            ></el-input>
          </el-form-item>
          <br />
          <el-form-item label="收款记录："></el-form-item>
          <el-table
            stripe
            :data="form.pay_list"
            show-summary
            :summary-method="tableTotal"
          >
            <el-table-column
              align="center"
              label="时间"
              prop="create_at"
            ></el-table-column>
            <el-table-column align="center" label="收款方式" prop="type">
              <template #default="{ row }">
                <el-select
                  v-model="row.pay_type"
                  clearable
                  placeholder="收款方式"
                >
                  <el-option
                    v-for="(i, idx) in allSelectList.arr_pay_type"
                    :key="idx"
                    :value="i.id"
                    :label="i.name"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column align="center" label="金额" prop="amount">
              <template #default="{ row }">
                <el-input v-model="row.amount"></el-input>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="收款人"
              prop="user_name"
            ></el-table-column>
            <el-table-column align="center" label="操作" prop="">
              <template #default="{ $index }">
                <el-button type="text" @click="handleAdd($index)">
                  添加
                </el-button>
                <el-button type="text" @click="handleDelete($index)">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>

          <!-- <el-form-item
            v-for="(item1, index1) in pay_data"
            :key="index1"
            label="收款金额:"
          >
            <div style="display: flex">
              <el-input
                v-model.trim="item1.money"
                style="width: 90px; margin-right: 20px"
              ></el-input>
              <el-select
                v-model="item1.pay_type"
                style="width: 90px; margin-right: 10px"
              >
                <el-option
                  v-for="item_1 in allSelectList.arr_pay_type"
                  :key="item_1.id"
                  :label="item_1.name"
                  :value="item_1.id"
                ></el-option>
              </el-select>
              <el-select v-model="item1.user_id" style="width: 90px">
                <el-option
                  v-for="item_1 in selectPeopleList"
                  :key="item_1.user_id"
                  :label="item_1.user_name"
                  :value="item_1.user_id"
                ></el-option>
              </el-select>
            </div>
            <i
              v-if="index1 == 0"
              class="el-icon-circle-plus addbtn"
              @click="addInput"
            ></i>
          </el-form-item> -->
        </el-form>
      </div>
      <!--   协议赠品   -->
      <el-dialog
        width="60%"
        title="协议赠品"
        :visible.sync="showgive"
        append-to-body
      >
        <div>
          <el-table stripe :data="form.give_list_front">
            <el-table-column
              prop=""
              label="商品名称"
              width="auto"
              align="center"
            >
              <template #default="{ row }">
                <template>
                  <goods-search
                    :f-key="row.goods_name"
                    :search="false"
                    :show-kouwei="false"
                    @select-goods-all="selectGoods($event, row)"
                  ></goods-search>
                </template>
              </template>
            </el-table-column>
            <!--            <el-table-column-->
            <!--              prop="goods_specs"-->
            <!--              label="规格"-->
            <!--              width="auto"-->
            <!--              align="center"-->
            <!--            ></el-table-column>-->
            <el-table-column
              prop="unit_id"
              label="单位"
              width="auto"
              align="center"
            >
              <template #default="{ $index, row }">
                <template>
                  <el-select
                    v-model="row.unit_id"
                    placeholder="选择单位"
                    style="width: 70px"
                    @change="unitChange($index, $event, row)"
                  >
                    <el-option
                      v-for="item in row.unit_list"
                      :key="item.unit_id"
                      :label="item.unit_name"
                      :value="item.unit_id"
                    ></el-option>
                  </el-select>
                </template>
              </template>
            </el-table-column>
            <el-table-column
              prop="goods_num"
              label="赠送数量"
              width="auto"
              align="center"
            >
              <template #default="{ row }">
                <template>
                  <el-input v-model="row.goods_num" style="width: 80px" />
                </template>
              </template>
            </el-table-column>
            <el-table-column prop="" label="操作" width="auto" align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="copy(scope.$index, scope.row)">
                  复制
                </el-button>
                <el-button
                  type="text"
                  @click="deleter(scope.$index, scope.row)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-dialog>
      <!--     限定商品 -->
      <el-dialog
        width="60%"
        title="限定商品"
        :visible.sync="showspecs"
        append-to-body
      >
        <div>
          <el-row type="flex" class="row-bg" justify="space-between">
            <span>限定品牌：</span>
            <edit-brand ref="tree" @treelit="treelit"></edit-brand>
          </el-row>
          <el-row :gutter="20" style="padding: 10px 0px">
            <el-col
              v-for="(item, indexd) in form.brand_list"
              :key="indexd"
              :span="6"
            >
              <el-button class="el-icon-close" @click="deletetree(indexd)">
                {{ item.brand_name }}
              </el-button>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" justify="space-between">
            <span>限定商品：</span>
            <edit-goods ref="goods" @addgoos="addgoos"></edit-goods>
          </el-row>
          <!-- table表格 sche_list[0].goods_list -->
          <el-row>
            <el-table stripe :data="form.goods_list_front" max-height="300">
              <el-table-column type="index" width="50"></el-table-column>
              <el-table-column
                v-for="(list, indexs) in comle"
                :key="indexs"
                :prop="list.prop"
                :label="list.label"
                :align="list.align"
                width=""
              >
                <template v-if="list.label == '下单价'" #default="{ row }">
                  <el-input v-model="row.goods_price"></el-input>
                </template>
                <template v-else #default="{ row }">
                  {{ row[list.prop] }}
                </template>
              </el-table-column>
              <el-table-column label="操作" prop="" align="center" width="auto">
                <template #default="{ $index, row }">
                  <el-button type="text" @click="deletetab($index, row)">
                    删 除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </div>
      </el-dialog>
      <div slot="footer" class="dislog-footer">
        <el-button type="primary" @click="primary">审核</el-button>
        <!-- <el-button type="primary">保存</el-button> -->
        <el-button @click="showreview = !showreview">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { peopleList } from '@/api/createPreInventory'
  import { getAllSelect } from '@/api/advanceOrder'
  import { postAction } from '@/api/Employee'
  import { dateFormat } from '@/utils/Time'
  export default {
    components: {
      GoodsSearch: () => import('@/baseComponents/goodsSearch'),
      EditBrand: () => import('./EditBrand'),
      EditGoods: () => import('./EditGoods'),
    },
    // components: { Template },
    // router.beforeEach(to,from,next()){},
    data() {
      return {
        showreview: false,
        form: {
          goods_list_front: [], //限定商品
          give_list_front: [], //协议赠品
          brand_list: [],
        },
        url: {
          info: '/promoteAdmin/deposit-order/detail',
          update: '/promoteAdmin/deposit-order/update',
        },
        selectPeopleList: [],
        allSelectList: [],
        pay_data: [{ money: '', pay_type: '', user_id: '' }],
        showgive: false,
        showspecs: false,
        comle: [
          {
            prop: 'goods_name',
            label: '商品名称',
            align: 'center',
            width: 'auto',
          },
          {
            prop: 'goods_specs',
            label: '规格',
            align: 'center',
            width: 'auto',
          },
          {
            prop: 'unit_name',
            label: '单位',
            align: 'center',
            width: 'auto',
          },
          {
            prop: 'goods_price',
            label: '下单价',
            align: 'center',
            width: 'auto',
          },
        ],
      }
    },

    computed: {},
    watch: {
      showreview(val) {
        if (!val) this.pay_data = [{ money: '', pay_type: '', user_id: '' }]
      },
      showgive(val) {
        if (!val) {
          let gives = this.form.give_list_front.filter((list) => {
            console.log(list)
            return list.goods_id != ''
          })
          this.form.give_list_front = gives
          console.log(gives, '这个是啥')
        }
      },
    },
    created() {},
    mounted() {
      console.log('父节点实例', this.$parent, this.$root, '父节点实例')
      // 收款人下拉
      peopleList().then((res) => {
        if (res.code == 200) {
          console.log(res.data)
          this.selectPeopleList = res.data
        }
      })
      // paytype 下拉
      getAllSelect().then((res) => {
        if (res.code == 200) {
          console.log(res.data)
          this.allSelectList = res.data
          this.allSelectList.arr_pay_type.forEach((i) => {
            i.id = Number(i.id)
          })
        }
      })
    },
    methods: {
      primary() {
        // let pay_list = JSON.parse(JSON.stringify(this.pay_data))
        let pay_list = JSON.parse(JSON.stringify(this.form.pay_list))
        pay_list.forEach((i) => {
          i.money = i.amount
        })
        if (pay_list.length == 0) {
          pay_list = ''
        } else {
          pay_list = JSON.stringify(pay_list)
        }
        var data = {
          id: this.form.order_id,
          total_amount: this.form.total_amount,
          gift_amount: this.form.gift_amount,
          note: this.form.note,
          gift_goods_type: this.form.gift_goods_type,
          gift_status: this.form.gift_status,
          price_status: this.form.price_status,
          exceed_status: this.form.exceed_status,
          goods_data: [],
          give_data: [],
          brand_data: JSON.parse(
            JSON.stringify(this.form.sche_list[0].brand_list)
          ),
          pay_list,
        }
        console.log('this.form.goods_list_front', this.form.goods_list_front)
        data.goods_data = this.form.goods_list_front
        // if (this.form.goods_list_front.length > 0) {
        //   this.form.goods_list_front.forEach((item) => {
        //     let arr = {
        //       goods_id: item.goods_id,
        //       unit_data: [],
        //     }
        //     if (item.unit_list.length > 0) {
        //       item.unit_list.forEach((list) => {
        //         let uniarr = {
        //           unit_id: list.unit_id,
        //           goods_price: item.goods_price,
        //         }
        //         arr.unit_data.push(uniarr)
        //       })
        //     }
        //     data.goods_data.push(arr)
        //   })
        // }
        if (this.form.give_list_front.length > 0) {
          this.form.give_list_front.forEach((item) => {
            let arr = {
              goods_id: item.goods_id,
              unit_data: [
                {
                  unit_id: item.unit_id,
                  unit_num: item.goods_num,
                },
              ],
            }

            data.give_data.push(arr)
          })
        }
        if (data.goods_data.length > 0) {
          data.goods_data = JSON.stringify(data.goods_data)
        } else {
          delete data.goods_data
        }
        if (data.give_data.length > 0) {
          data.give_data = JSON.stringify(data.give_data)
        } else {
          delete data.give_data
        }
        if (data.brand_data.length > 0) {
          data.brand_data = JSON.stringify(data.brand_data)
        } else {
          delete data.brand_data
        }
        // data.pay_list = JSON.stringify(data.pay_list)
        console.log('data', data)
        postAction(this.url.update, data)
          .then((res) => {
            console.log(res.msg)
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.showreview = false
            this.$emit('getlist')
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlergive(row) {
        console.log(row, '点击了吗')
        this.showgive = true
        if (this.form.give_list_front.length > 0) {
        } else {
          let listgive = {
            goods_convert: '',
            goods_id: '',
            goods_name: '',
            goods_num: '',
            goods_num_use: '',
            goods_specs: null,
            note: '',
            unit_id: '',
            unit_list: [],
          }
          this.form.give_list_front.push(listgive)
        }
      },
      handlergoodslist() {
        this.showspecs = true
      },
      datalist(row) {
        console.log(row)
      },
      xianding(row) {
        console.log(row)
      },
      addInput() {
        this.pay_data.push({ money: '', pay_type: '' })
      },
      handlerdata(id) {
        console.log(id)
        postAction(this.url.info, { id })
          .then((res) => {
            console.log(res, '这个是详情吗？')
            this.form = res.data
            // Object.assign(this.form, res.data)
            if (this.form.pay_list.length == 0) {
              this.addPayList()
            }
            console.log(this.form)
          })
          .catch((err) => {
            console.log(err)
          })
      },
      treelit(res) {
        let brand = res.map((list) => {
          return {
            brand_id: list.id,
            brand_name: list.brand_name,
          }
        })

        this.form.brand_list = brand
      },
      addPayList() {
        this.form.pay_list.splice(this.form.pay_list.length, 0, {
          amount: '',
          create_at: dateFormat(new Date()),
          pay_type: '',
          user_name: this.form.staff_name,
        })
      },
      /*
       * 添加限定商品
       * */
      addgoos(res) {
        console.log(res, '选后的商品')
        var setgoods = [...this.form.goods_list_front]
        console.log(setgoods.length)
        for (var j = 0; j < setgoods.length; j++) {
          console.log(setgoods[j], '222222222')
          var list = setgoods[j]
          for (var i = 0; i < res.length; i++) {
            console.log(res[i], '33333333')
            if (list.goods_id === res[i].goods_id) {
              console.log(list.goods_id)
              res.splice(i, 1)
              break
            }
          }
        }
        let listres = res.map((list) => {
          return {
            goods_id: list.goods_id,
            goods_name: list.goods_name,
            goods_specs: list.specs,
            brand_name: list.brand_name,
            brand_id: list.brand_id,
            unit_id: list.unit_id,
            unit_name: list.goods_unit,
            goods_price: list.sell_price,
            unit_list: [
              {
                unit_id: list.unit_id,
                goods_price: list.price,
                unit_name: list.goods_unit,
              },
            ],
          }
        })
        this.form.goods_list_front.push(...listres)
      },
      /*
       * 协议赠品下拉框
       * */
      selectGoods(val, row) {
        console.log('val', val, row)

        try {
          let listgoods = {
            goods_id: val.goods_id,
            goods_name: val.goods_name,
            goods_specs: val.specs,
            unit_id: val.unit_id,
            unit_name: val.unit_name,
            goods_num: val.quantity,
            unit_list: val.arr_unit,
            goods_convert: '',
            goods_num_use: '',
            note: '',
          }
          Object.assign(row, listgoods)
        } catch (e) {
          console.error(e)
        }
      },
      // 表格内单位切换
      unitChange(index, e, row) {
        console.log(e, row)
        let price = row.unit_list.filter((item) => item.unit_id == e)[0]
          .goods_price
        row.goods_price = price
        let unitname = row.unit_list.filter((item) => item.unit_id == e)[0]
          .unit_name
        console.log(unitname, '单位')
        row.unit_name = unitname
      },
      copy(index, row) {
        console.log(index, row, '什么')
        let rowS = JSON.parse(JSON.stringify(row))
        // let rowa = Object.assign(rowS, { id: row.id++, edit: false })
        this.form.give_list_front.splice(index, 0, rowS)
      },
      deleter(index, row) {
        this.form.give_list_front.splice(index, 1)
      },
      deletetree(index) {
        this.form.brand_list.splice(index, 1)
      },
      deletetab(index, row) {
        this.form.goods_list_front.splice(index, 1)
      },

      handleAdd(idx) {
        this.addPayList()
      },
      handleDelete(idx) {
        this.form.pay_list.splice(idx, 1)
      },
      tableTotal(param) {
        const { columns, data } = param
        // 需要合计的下标
        let i = [2]
        let sums = []
        // let val = data.map((item) => {
        //   console.log(item)
        // })
        columns.forEach((col, idx) => {
          i.forEach((n) => {
            if (idx == n) {
              console.log(col.property)
              const val = data.map((item) => Number(item[col.property]))
              console.log(val)
              if (!val.every((value) => isNaN(value))) {
                const a = val.reduce((total, num) => {
                  if (!isNaN(total)) {
                    return total + num
                  }
                })
                sums[n] = a.toFixed(2) + ' 元'
              }
            }
          })
          if (idx == 0) {
            sums[idx] = '合计'
          }
        })
        console.log('sss', sums)
        return sums
      },
    },
  }
</script>
<style lang="scss" scoped>
  .addbtn {
    position: absolute;
    top: 0;
    right: -55px;
    margin-right: 15px;
    margin-left: 15px;
    font-size: 32px;
    color: #84bc39;
  }
  .textstaly {
    text-decoration: underline;
    cursor: pointer;
  }
</style>
