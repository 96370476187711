var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("span", { staticStyle: { padding: "0px 10px" } }, [
        _vm._v(_vm._s(_vm.tableData.length)),
      ]),
      _c("i", {
        staticClass: "el-icon-edit-outline",
        on: {
          click: function ($event) {
            _vm.dialogVisible = true
          },
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            title: "协议赠品",
            visible: _vm.dialogVisible,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-table",
                {
                  ref: "singleTable",
                  staticStyle: { width: "100%" },
                  attrs: { stripe: "", data: _vm.tableData },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "index", width: "50" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "",
                      label: "商品名称",
                      width: "auto",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var $index = ref.$index
                          var row = ref.row
                          return [
                            _vm.edit
                              ? [
                                  _c(
                                    "el-autocomplete",
                                    {
                                      staticClass: "inline-input",
                                      attrs: {
                                        "popper-class": "selectDC",
                                        "fetch-suggestions": _vm.querySearch2,
                                        "value-key": "goods_name",
                                        placeholder: "请输入商品名称、简拼",
                                      },
                                      on: {
                                        select: function ($event) {
                                          return _vm.handleSelect(
                                            $index,
                                            row,
                                            $event
                                          )
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var item = ref.item
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "wrap",
                                                    staticStyle: {
                                                      display: "flex",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "name",
                                                        staticStyle: {
                                                          width: "200px",
                                                          "margin-right":
                                                            "120px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.goods_name
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      { staticClass: "addr" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.specs)
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: row.goods_name,
                                        callback: function ($$v) {
                                          _vm.$set(row, "goods_name", $$v)
                                        },
                                        expression: "row.goods_name",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-input__icon el-icon-search",
                                        attrs: { slot: "suffix" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.iconClick($index, row)
                                          },
                                        },
                                        slot: "suffix",
                                      }),
                                    ]
                                  ),
                                ]
                              : _c("span", [_vm._v(_vm._s(row.goods_name))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "specs",
                      label: "规格",
                      width: "auto",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "unit_id",
                      label: "单位",
                      width: "auto",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var $index = ref.$index
                          var row = ref.row
                          return [
                            _vm.edit
                              ? [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "70px" },
                                      attrs: { placeholder: "选择单位" },
                                      on: {
                                        change: function ($event) {
                                          return _vm.unitChange(
                                            $index,
                                            $event,
                                            row
                                          )
                                        },
                                      },
                                      model: {
                                        value: row.unit_id,
                                        callback: function ($$v) {
                                          _vm.$set(row, "unit_id", $$v)
                                        },
                                        expression: "row.unit_id",
                                      },
                                    },
                                    _vm._l(row.arr_unit, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.unit_name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ]
                              : _c("span", [_vm._v(_vm._s(row.units))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "quantity",
                      label: "数量",
                      width: "auto",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var $index = ref.$index
                          var row = ref.row
                          return [
                            _vm.edit
                              ? [
                                  _c("el-input", {
                                    staticStyle: { width: "80px" },
                                    on: {
                                      input: function ($event) {
                                        return _vm.moneyN(row, $index)
                                      },
                                    },
                                    model: {
                                      value: row.quantity,
                                      callback: function ($$v) {
                                        _vm.$set(row, "quantity", $$v)
                                      },
                                      expression: "row.quantity",
                                    },
                                  }),
                                ]
                              : _c("span", [_vm._v(_vm._s(row.quantity))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "",
                      label: "操作",
                      width: "auto",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.copy(scope.$index, scope.row)
                                  },
                                },
                              },
                              [_vm._v(" 复制 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleter(scope.$index, scope.row)
                                  },
                                },
                              },
                              [_vm._v(" 删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.xieyi } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }