<!--
 * @Author: 候怀烨
 * @Date: 2020-12-03 14:21:23
 * @LastEditTime: 2021-04-06 16:50:53
 * @LastEditors: Please set LastEditors
 * @Description: 协议赠品
 * @FilePath: \sd-vue-admin\src\views\project\movablepin\predeposit\depositactivity\components\GiveawayDing.vue
-->
<template>
  <div>
    <span style="padding: 0px 10px">{{ tableData.length }}</span>
    <i class="el-icon-edit-outline" @click="dialogVisible = true"></i>
    <el-dialog
      :modal="false"
      title="协议赠品"
      :visible.sync="dialogVisible"
      width="50%"
    >
      <div>
        <!-- 表格 -->
        <el-table
          ref="singleTable"
          stripe
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column type="index" width="50"></el-table-column>
          <el-table-column prop="" label="商品名称" width="auto" align="center">
            <template #default="{ $index, row }">
              <template v-if="edit">
                <el-autocomplete
                  v-model="row.goods_name"
                  class="inline-input"
                  popper-class="selectDC"
                  :fetch-suggestions="querySearch2"
                  value-key="goods_name"
                  placeholder="请输入商品名称、简拼"
                  @select="handleSelect($index, row, $event)"
                >
                  <!--                   @input="addRow($index, row, $event)"-->
                  <i
                    slot="suffix"
                    class="el-input__icon el-icon-search"
                    @click="iconClick($index, row)"
                  ></i>
                  <template slot-scope="{ item }">
                    <div class="wrap" style="display: flex">
                      <div
                        class="name"
                        style="width: 200px; margin-right: 120px"
                      >
                        {{ item.goods_name }}
                      </div>
                      <span class="addr">{{ item.specs }}</span>
                    </div>
                  </template>
                </el-autocomplete>
                <!-- <goods-search :goods_keyword="row.goods_name"></goods-search> -->
              </template>
              <span v-else>{{ row.goods_name }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="specs"
            label="规格"
            width="auto"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="unit_id"
            label="单位"
            width="auto"
            align="center"
          >
            <template #default="{ $index, row }">
              <template v-if="edit">
                <!-- <el-input v-model="row.type" style="width: 100px" /> -->
                <el-select
                  v-model="row.unit_id"
                  placeholder="选择单位"
                  style="width: 70px"
                  @change="unitChange($index, $event, row)"
                >
                  <el-option
                    v-for="item in row.arr_unit"
                    :key="item.id"
                    :label="item.unit_name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </template>
              <span v-else>{{ row.units }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="quantity"
            label="数量"
            width="auto"
            align="center"
          >
            <template #default="{ $index, row }">
              <template v-if="edit">
                <el-input
                  v-model="row.quantity"
                  style="width: 80px"
                  @input="moneyN(row, $index)"
                />
              </template>
              <span v-else>{{ row.quantity }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="" label="操作" width="auto" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="copy(scope.$index, scope.row)">
                复制
              </el-button>
              <el-button type="text" @click="deleter(scope.$index, scope.row)">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="xieyi">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { getInputList } from '@/api/goodsSearchInput'
  import { postAction } from '@/api/Employee'
  export default {
    props: {
      index: {
        type: Number,
        default() {
          return 0
        },
      },
    },
    data() {
      return {
        dialogVisible: false,
        tableData: [],
        edit: true,
      }
    },
    computed: {
      table() {
        // var a = this.tableData.filter((list) => {
        //   console.log(list, '111')
        //   return list.goods_id != ''
        // })
        // console.log(a, '1111111111')
        return this.tableData
      },
    },
    watch: {
      tableData(val) {
        console.log(val)
      },
      dialogVisible(val) {
        if (val) {
          this.tableData = [{}]
        }
      },
    },
    methods: {
      kong() {
        this.tableData = []
      },
      xieyi() {
        var a = this.tableData
        this.$emit('xieyi', a, this.index)
        this.dialogVisible = false
        var b = this.tableData.filter((list) => {
          console.log(list, '111')
          return list.goods_id != ''
        })
        this.tableData = b
      },
      // 表格内单位切换
      unitChange(index, e, row) {
        let unitname = row.arr_unit.filter((item) => item.id == e)[0].unit_name
        console.log(unitname, '单位')
        row.unit_name = unitname
        console.log('表格内单位切换', index, e, row.arr_unit)
        let price = row.arr_unit.filter((item) => item.id == e)[0].sell_price
        row.goods_price = price
      },
      moneyN(row, index) {},
      // 商品搜索下拉
      async fetchData(queryString) {
        let { data, code, msg } = await getInputList({
          pageNo: 1,
          pageSize: -1,
          keyword: queryString,
        })
        console.log(data)
        if (code == 200) {
          return data
        } else {
          this.$message.error(msg)
        }
      },
      copy(index, row) {
        console.log(index, row, '什么')
        let rowS = JSON.parse(JSON.stringify(row))
        // let rowa = Object.assign(rowS, { id: row.id++, edit: false })
        this.tableData.splice(index, 0, rowS)
      },
      deleter(index, row) {
        if (index == this.tableData.length - 1) {
          // alert('不能删除最后一行')
          this.$message.error('不能删除最后一行')
          return
        }
        this.tableData.splice(index, 1)
      },
      // 添加行
      addRow(index, row, $event) {
        console.log(index, $event)
        if (this.tableData.length == index + 1) {
          this.tableData.push({
            activity_code: '',
            activity_id: 0,
            activity_type: 0,
            balance_count: 0,
            depot_stock_num: 0,
            edit: true,
            goods_child_id: null,
            goods_id: 1,
            goods_money: '',
            goods_name: '',
            goods_price: '',
            goods_production_date: '',
            goods_type: '',
            info: '',
            money: '',
            quantity: '',
            specs: '',
            unit_id: '',
            unit_name: '',
          })
        }
      },
      handleSelect(index, row, $event) {
        console.log(index, row, $event, '看看是什么')
        this.$forceUpdate()
        // Object.assign(row, $event)
        this.$set(row, '', $event)
        this.tableData[index] = $event
        this.tableData[index].goods_type = 1
        this.tableData[index].unit_id = $event.arr_unit[0].unit_id
        this.tableData[index].unit_name = $event.arr_unit[0].unit_name
        this.tableData[index].edit = false
        console.log(this.tableData[index])
      },
      // 表格内商品名称输入框搜索
      querySearch2(queryString, cb) {
        if (queryString == '') {
          cb([])
        } else {
          this.fetchData(queryString).then((val) => {
            console.log(val)
            cb(val)
          })
        }
      },
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then((_) => {
            done()
          })
          .catch((_) => {})
      },
    },
  }
</script>

<style></style>
