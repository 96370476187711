var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              _vm.newshow = true
            },
          },
        },
        [_vm._v("添加客户预存款")]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            "close-on-click-modal": false,
            title: "添加客户预存款",
            visible: _vm.newshow,
            width: "70%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.newshow = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.form, "label-width": "100px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "预存活动：", prop: "deposit_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            placeholder: "请选择预存款活动",
                            disabled: _vm.form.newHd,
                          },
                          on: { change: _vm.select },
                          model: {
                            value: _vm.form.deposit_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "deposit_id", $$v)
                            },
                            expression: "form.deposit_id",
                          },
                        },
                        _vm._l(_vm.options, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                      _c(
                        "el-checkbox",
                        {
                          staticStyle: { "margin-left": "30px" },
                          model: {
                            value: _vm.form.newHd,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "newHd", $$v)
                            },
                            expression: "form.newHd",
                          },
                        },
                        [_vm._v(" 自定义预存款 ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "业务员：", prop: "staff_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { placeholder: "请选择业务员" },
                          model: {
                            value: _vm.form.staff_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "staff_id", $$v)
                            },
                            expression: "form.staff_id",
                          },
                        },
                        _vm._l(_vm.ywlist, function (item) {
                          return _c("el-option", {
                            key: item.user_id,
                            attrs: {
                              label: item.user_name,
                              value: item.user_id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户名称：", prop: "name" } },
                    [
                      _c("client-search", {
                        ref: "clientSearch",
                        staticStyle: { width: "210px" },
                        attrs: { "popper-class": "selectDC" },
                        on: { "select-val-alldata": _vm.clientSelect },
                      }),
                      _c("span", { staticStyle: { "margin-left": "30px" } }, [
                        _vm._v(
                          " 联系人：" +
                            _vm._s(_vm.form.boss) +
                            _vm._s(_vm.form.mobile) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "地址：", prop: "address" } },
                    [_c("span", [_vm._v(_vm._s(_vm.form.address))])]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注：", prop: "note" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "250px" },
                        attrs: { placeholder: "可以输入备注" },
                        model: {
                          value: _vm.form.note,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "note", $$v)
                          },
                          expression: "form.note",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "预存方案：", prop: "sche_list" } },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.form.newHd,
                              expression: "!form.newHd",
                            },
                          ],
                          staticStyle: { width: "75%" },
                          attrs: {
                            stripe: "",
                            border: "",
                            data: _vm.form.sche_list,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { width: "60" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: row.id, name: row.id },
                                        on: {
                                          change: function ($event) {
                                            return _vm.change(row)
                                          },
                                        },
                                        model: {
                                          value: _vm.form.fangaid,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "fangaid", $$v)
                                          },
                                          expression: "form.fangaid",
                                        },
                                      },
                                      [_c("i")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "方案",
                              width: "80",
                              type: "index",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "预存金额",
                              width: "auto",
                              prop: _vm.form.sche_list.total_amount,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-input", {
                                      attrs: { placeholder: "请输入预存金额" },
                                      on: {
                                        input: function ($event) {
                                          return _vm.addrow(scope.$index)
                                        },
                                      },
                                      model: {
                                        value: scope.row.total_amount,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "total_amount",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.total_amount",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "赠送金额",
                              width: "auto",
                              prop: _vm.form.sche_list.gift_amount,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-input", {
                                      attrs: { placeholder: "请输入赠送金额" },
                                      model: {
                                        value: scope.row.gift_amount,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "gift_amount",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.gift_amount",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "协议赠品",
                              width: "auto",
                              prop: _vm.form.sche_list.give_data,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          padding: "0px 10px",
                                          "text-decoration": "underline",
                                          cursor: "pointer",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.zpc(
                                              _vm.form.sche_list[scope.$index]
                                                .give_data
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.form.sche_list[scope.$index]
                                                .give_data.length
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "限定商品",
                              width: "auto",
                              prop: _vm.form.sche_list.goods_data,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          padding: "0px 10px",
                                          "text-decoration": "underline",
                                          cursor: "pointer",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.shangpin(
                                              _vm.form.sche_list[scope.$index]
                                                .goods_data,
                                              _vm.form.sche_list[scope.$index]
                                                .brand_data
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.form.sche_list[scope.$index]
                                                .goods_data.length
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.form.newHd,
                              expression: "form.newHd",
                            },
                          ],
                        },
                        [
                          _c("customize", {
                            ref: "xiandingshangpin",
                            on: { list: _vm.tablelis, sangpin: _vm.sangpin },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c("el-switch", {
                                attrs: {
                                  disabled: !_vm.form.newHd,
                                  "active-value": "1",
                                  "inactive-value": "0",
                                  "active-text": "活动可重复参加",
                                },
                                model: {
                                  value: _vm.form.repeat_status,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "repeat_status", $$v)
                                  },
                                  expression: "form.repeat_status",
                                },
                              }),
                              _c("br"),
                              _c("el-switch", {
                                attrs: {
                                  disabled: !_vm.form.newHd,
                                  "active-value": "1",
                                  "inactive-value": "0",
                                  "active-text": "下单时业务员可修改价格",
                                },
                                model: {
                                  value: _vm.form.price_status,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "price_status", $$v)
                                  },
                                  expression: "form.price_status",
                                },
                              }),
                              _c("br"),
                              _c("el-switch", {
                                attrs: {
                                  disabled: !_vm.form.newHd,
                                  "active-value": "1",
                                  "inactive-value": "0",
                                  "active-text":
                                    "允许超额下单：勾选后，客户可超出预存款单据余额下单，超出部分计入应收欠款",
                                },
                                model: {
                                  value: _vm.form.exceed_status,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "exceed_status", $$v)
                                  },
                                  expression: "form.exceed_status",
                                },
                              }),
                              _c("br"),
                              _c(
                                "el-radio",
                                {
                                  attrs: {
                                    disabled: !_vm.form.newHd,
                                    label: "1",
                                  },
                                  model: {
                                    value: _vm.form.check_status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "check_status", $$v)
                                    },
                                    expression: "form.check_status",
                                  },
                                },
                                [
                                  _vm._v(
                                    " 必须审核：所有预存款协议，必须后台审核后方可生效 "
                                  ),
                                ]
                              ),
                              _c(
                                "el-radio",
                                {
                                  attrs: {
                                    disabled: !_vm.form.newHd,
                                    label: "0",
                                  },
                                  model: {
                                    value: _vm.form.check_status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "check_status", $$v)
                                    },
                                    expression: "form.check_status",
                                  },
                                },
                                [
                                  _vm._v(
                                    " 异常审核：勾选后，业务员修改协议内容，需后台审核后方可生效 "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c("span", [_vm._v("赠品赠送条件：")]),
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.form.gift_goods_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "gift_goods_type", $$v)
                                },
                                expression: "form.gift_goods_type",
                              },
                            },
                            _vm._l(_vm.citiesing, function (city) {
                              return _c(
                                "el-radio",
                                {
                                  key: city.id,
                                  attrs: {
                                    disabled: !_vm.form.newHd,
                                    label: city.id,
                                  },
                                },
                                [_vm._v(" " + _vm._s(city.name) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.newHd,
                          expression: "form.newHd",
                        },
                      ],
                      attrs: { label: "签单金额：", prop: "total_amount" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "150px" },
                        attrs: {
                          type: "number",
                          placeholder: "请输入签单金额",
                          min: "0",
                        },
                        model: {
                          value: _vm.form.total_amount,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "total_amount", $$v)
                          },
                          expression: "form.total_amount",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.newHd,
                          expression: "form.newHd",
                        },
                      ],
                      attrs: { label: "赠送金额：", prop: "gift_amount" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "150px" },
                        attrs: {
                          type: "number",
                          placeholder: "请输入签单金额",
                          min: "0",
                        },
                        model: {
                          value: _vm.form.gift_amount,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "gift_amount", $$v)
                          },
                          expression: "form.gift_amount",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._l(_vm.form.pay_data, function (list, index) {
                    return _c(
                      "el-form-item",
                      {
                        key: list.id,
                        attrs: { label: "收款金额：", prop: "pay_data" },
                      },
                      [
                        _c("el-input", {
                          staticStyle: {
                            width: "150px",
                            "margin-right": "10px",
                          },
                          attrs: {
                            type: "number",
                            placeholder: "请输入收款金额",
                            min: "0",
                          },
                          on: { blur: _vm.moneytrue },
                          model: {
                            value: list.money,
                            callback: function ($$v) {
                              _vm.$set(list, "money", $$v)
                            },
                            expression: "list.money",
                          },
                        }),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "120px" },
                            model: {
                              value: list.pay_type,
                              callback: function ($$v) {
                                _vm.$set(list, "pay_type", $$v)
                              },
                              expression: "list.pay_type",
                            },
                          },
                          _vm._l(_vm.paytype, function (lists) {
                            return _c("el-option", {
                              key: lists.id,
                              attrs: {
                                placeholder: "请选择",
                                label: lists.name,
                                value: lists.id,
                              },
                            })
                          }),
                          1
                        ),
                        index == 0
                          ? _c("i", {
                              staticClass: "el-icon-circle-plus addbtn",
                              on: { click: _vm.addInput },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.newshow = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("collections-sperclass", { ref: "superclass" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }