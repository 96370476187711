var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            top: "5vh",
            "close-on-click-modal": false,
            modal: false,
            center: "",
            title: "预存款单审核",
            visible: _vm.showreview,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showreview = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "ruleform",
                  attrs: {
                    model: _vm.form,
                    inline: "",
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "单号：", prop: "" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "", placeholder: "单号" },
                        model: {
                          value: _vm.form.bill_code,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "bill_code", $$v)
                          },
                          expression: "form.bill_code",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "签单：" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "", placeholder: "签单" },
                        model: {
                          value: _vm.form.create_at,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "create_at", $$v)
                          },
                          expression: "form.create_at",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户名称：" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "", placeholder: "客户名称" },
                        model: {
                          value: _vm.form.cust_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "cust_name", $$v)
                          },
                          expression: "form.cust_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系人：" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "", placeholder: "联系人" },
                        model: {
                          value: _vm.form.boss,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "boss", $$v)
                          },
                          expression: "form.boss",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "地址：" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "", placeholder: "地址" },
                        model: {
                          value: _vm.form.address,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "address", $$v)
                          },
                          expression: "form.address",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动名称：" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "", placeholder: "活动名称" },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "业务员：" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "", placeholder: "业务员" },
                        model: {
                          value: _vm.form.staff_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "staff_name", $$v)
                          },
                          expression: "form.staff_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注：" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "", placeholder: "备注" },
                        model: {
                          value: _vm.form.note,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "note", $$v)
                          },
                          expression: "form.note",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("br"),
                  _c("el-form-item", { attrs: { label: "预存方案：" } }),
                  _c(
                    "el-table",
                    { attrs: { stripe: "", data: _vm.form.sche_list } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "预存金额",
                          prop: "total_amount",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "赠送金额",
                          prop: "gift_amount",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "协议赠品", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass: "textstaly",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlergive(row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.form.give_list_front.length
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c(
                        "el-table-column",
                        { attrs: { label: "限定商品", align: "center" } },
                        [
                          [
                            _c(
                              "span",
                              {
                                staticClass: "textstaly",
                                on: { click: _vm.handlergoodslist },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.form.goods_list_front.length) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-value": "1",
                          "inactive-value": "0",
                          "active-text": "下单时业务员可修改价格",
                        },
                        model: {
                          value: _vm.form.price_status,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "price_status", $$v)
                          },
                          expression: "form.price_status",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "el-form-item",
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-value": "1",
                          "inactive-value": "0",
                          "active-text":
                            "允许超额下单：客户可超出预存款单据余额下单，超出部分计入应收欠款",
                        },
                        model: {
                          value: _vm.form.exceed_status,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "exceed_status", $$v)
                          },
                          expression: "form.exceed_status",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "el-form-item",
                    { attrs: { label: "赠品赠送条件：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.gift_goods_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "gift_goods_type", $$v)
                            },
                            expression: "form.gift_goods_type",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "1" } }, [
                            _vm._v("无限制"),
                          ]),
                          _c("el-radio", { attrs: { label: "2" } }, [
                            _vm._v("付款完成可下赠品"),
                          ]),
                          _c("el-radio", { attrs: { label: "3" } }, [
                            _vm._v("消费完后可下赠品"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "el-form-item",
                    { attrs: { label: "签单金额：" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "签单金额" },
                        model: {
                          value: _vm.form.total_amount,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "total_amount", $$v)
                          },
                          expression: "form.total_amount",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "赠送金额：" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "赠送金额" },
                        model: {
                          value: _vm.form.gift_amount,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "gift_amount", $$v)
                          },
                          expression: "form.gift_amount",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("br"),
                  _c("el-form-item", { attrs: { label: "收款记录：" } }),
                  _c(
                    "el-table",
                    {
                      attrs: {
                        stripe: "",
                        data: _vm.form.pay_list,
                        "show-summary": "",
                        "summary-method": _vm.tableTotal,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "时间",
                          prop: "create_at",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "收款方式",
                          prop: "type",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "收款方式",
                                    },
                                    model: {
                                      value: row.pay_type,
                                      callback: function ($$v) {
                                        _vm.$set(row, "pay_type", $$v)
                                      },
                                      expression: "row.pay_type",
                                    },
                                  },
                                  _vm._l(
                                    _vm.allSelectList.arr_pay_type,
                                    function (i, idx) {
                                      return _c("el-option", {
                                        key: idx,
                                        attrs: { value: i.id, label: i.name },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "金额",
                          prop: "amount",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("el-input", {
                                  model: {
                                    value: row.amount,
                                    callback: function ($$v) {
                                      _vm.$set(row, "amount", $$v)
                                    },
                                    expression: "row.amount",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "收款人",
                          prop: "user_name",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "操作", prop: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var $index = ref.$index
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleAdd($index)
                                      },
                                    },
                                  },
                                  [_vm._v(" 添加 ")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDelete($index)
                                      },
                                    },
                                  },
                                  [_vm._v(" 删除 ")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                width: "60%",
                title: "协议赠品",
                visible: _vm.showgive,
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showgive = $event
                },
              },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-table",
                    { attrs: { stripe: "", data: _vm.form.give_list_front } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "",
                          label: "商品名称",
                          width: "auto",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                [
                                  _c("goods-search", {
                                    attrs: {
                                      "f-key": row.goods_name,
                                      search: false,
                                      "show-kouwei": false,
                                    },
                                    on: {
                                      "select-goods-all": function ($event) {
                                        return _vm.selectGoods($event, row)
                                      },
                                    },
                                  }),
                                ],
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "unit_id",
                          label: "单位",
                          width: "auto",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var $index = ref.$index
                              var row = ref.row
                              return [
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "70px" },
                                      attrs: { placeholder: "选择单位" },
                                      on: {
                                        change: function ($event) {
                                          return _vm.unitChange(
                                            $index,
                                            $event,
                                            row
                                          )
                                        },
                                      },
                                      model: {
                                        value: row.unit_id,
                                        callback: function ($$v) {
                                          _vm.$set(row, "unit_id", $$v)
                                        },
                                        expression: "row.unit_id",
                                      },
                                    },
                                    _vm._l(row.unit_list, function (item) {
                                      return _c("el-option", {
                                        key: item.unit_id,
                                        attrs: {
                                          label: item.unit_name,
                                          value: item.unit_id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "goods_num",
                          label: "赠送数量",
                          width: "auto",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "80px" },
                                    model: {
                                      value: row.goods_num,
                                      callback: function ($$v) {
                                        _vm.$set(row, "goods_num", $$v)
                                      },
                                      expression: "row.goods_num",
                                    },
                                  }),
                                ],
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "",
                          label: "操作",
                          width: "auto",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.copy(scope.$index, scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 复制 ")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleter(
                                          scope.$index,
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" 删除 ")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                width: "60%",
                title: "限定商品",
                visible: _vm.showspecs,
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showspecs = $event
                },
              },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-row",
                    {
                      staticClass: "row-bg",
                      attrs: { type: "flex", justify: "space-between" },
                    },
                    [
                      _c("span", [_vm._v("限定品牌：")]),
                      _c("edit-brand", {
                        ref: "tree",
                        on: { treelit: _vm.treelit },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    {
                      staticStyle: { padding: "10px 0px" },
                      attrs: { gutter: 20 },
                    },
                    _vm._l(_vm.form.brand_list, function (item, indexd) {
                      return _c(
                        "el-col",
                        { key: indexd, attrs: { span: 6 } },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "el-icon-close",
                              on: {
                                click: function ($event) {
                                  return _vm.deletetree(indexd)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.brand_name) + " ")]
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c(
                    "el-row",
                    {
                      staticClass: "row-bg",
                      attrs: { type: "flex", justify: "space-between" },
                    },
                    [
                      _c("span", [_vm._v("限定商品：")]),
                      _c("edit-goods", {
                        ref: "goods",
                        on: { addgoos: _vm.addgoos },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-table",
                        {
                          attrs: {
                            stripe: "",
                            data: _vm.form.goods_list_front,
                            "max-height": "300",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { type: "index", width: "50" },
                          }),
                          _vm._l(_vm.comle, function (list, indexs) {
                            return _c("el-table-column", {
                              key: indexs,
                              attrs: {
                                prop: list.prop,
                                label: list.label,
                                align: list.align,
                                width: "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  list.label == "下单价"
                                    ? {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _c("el-input", {
                                              model: {
                                                value: row.goods_price,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "goods_price",
                                                    $$v
                                                  )
                                                },
                                                expression: "row.goods_price",
                                              },
                                            }),
                                          ]
                                        },
                                      }
                                    : {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _vm._v(
                                              " " + _vm._s(row[list.prop]) + " "
                                            ),
                                          ]
                                        },
                                      },
                                ],
                                null,
                                true
                              ),
                            })
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              prop: "",
                              align: "center",
                              width: "auto",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var $index = ref.$index
                                  var row = ref.row
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deletetab($index, row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 删 除 ")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dislog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.primary } },
                [_vm._v("审核")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showreview = !_vm.showreview
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }