<!--
 * @Author: 侯怀烨
 * @Date: 2020-12-21 15:39:28
 * @LastEditTime: 2020-12-23 10:01:38
 * @LastEditors: Please set LastEditors
 * @Description: 自定义商品
 * @FilePath: \sd-vue-admin\src\views\project\movablepin\predeposit\depositreceipt\components\customize.vue
-->
<template>
  <div>
    <el-table stripe :data="table">
      <el-table-column label="协议赠品" width="auto" align="center">
        <!-- <span>{{ xieyi.length }}</span> -->
        <giveaway-ding ref="ding" @xieyi="datalist"></giveaway-ding>
      </el-table-column>
      <el-table-column label="指定商品" width="auto" align="center">
        <limited ref="limit" @xianding="xianding"></limited>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import limited from './limited.vue'
  import GiveawayDing from './GiveawayDing.vue'
  export default {
    components: {
      GiveawayDing,
      limited,
    },

    data() {
      return {
        table: [{}],
        xieyi: [],
        unber: 0,
        addgoods: [],
        treelist: [],
      }
    },
    methods: {
      datalist(row) {
        console.log(row, '')
        var goods = []
        for (let i = 0; i < row.length; i++) {
          var a = {
            // goods_id:data.give_data.goods_id,
            goods_id: row[i].goods_id,
            unit_data: [
              {
                unit_id: row[i].unit_id,
                goods_num: row[i].quantity,
              },
            ],
          }
          goods.push(a)
        }
        this.$emit('list', goods)
        console.log(goods, '组装后的')
        this.xieyi = [...goods]
        console.log(this.xieyi)
      },
      xianding(addgoods, treelist) {
        this.addgoods = addgoods
        this.treelist = treelist

        var goods_i = []
        for (let i = 0; i < addgoods.length; i++) {
          var a = {
            goods_id: addgoods[i].goods_id,
            unit_data: [
              {
                unit_id: addgoods[i].unit_id,
                goods_price: addgoods[i].price,
              },
            ],
          }
          goods_i.push(a)
        }
        var tree_i = []
        for (let i = 0; i < treelist.length; i++) {
          var a = {
            brand_id: treelist[i].id,
            brand_name: treelist[i].brand_name,
          }
          tree_i.push(a)
        }

        this.$emit('sangpin', goods_i, tree_i)
      },
      kong() {
        this.$refs.ding.kong()
        this.$refs.limit.kong()
      },
    },
  }
</script>

<style></style>
