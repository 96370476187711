<!--
 * @Author: 候怀烨
 * @Date: 2020-11-30 11:01:41
 * @LastEditTime: 2021-05-13 16:51:19
 * @LastEditors: Please set LastEditors
 * @Description: 客户预存款单据
 * @FilePath: \sd-vue-admin\src\views\project\movablepin\predeposit\depositreceipt\index.vue
-->
<template>
  <!-- NAME[epic=动销] 客户预存款单据 -->

  <div class="padding">
    <el-row type="flex" class="row-bg" justify="space-between">
      <el-col :span="50">
        <el-form ref="form" :model="form" inline>
          <el-form-item prop="deposit_id">
            <el-select
              v-model="form.deposit_id"
              placeholder="请选择预存款活动"
              style="width: 130px"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="is_create">
            <el-select
              v-model="form.is_create"
              placeholder="选择类型"
              style="width: 130px"
            >
              <el-option
                v-for="item in leixing"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-autocomplete
              v-model="name"
              placeholder="客户名称，助记码"
              suffix-icon="el-icon-search"
              value-key="cust_name"
              :fetch-suggestions="querySearchAsync"
              @select="handleSelect"
            ></el-autocomplete>
          </el-form-item>
          <el-form-item prop="staff_id">
            <el-select
              v-model="form.staff_id"
              placeholder="请选择业务员"
              style="width: 130px"
            >
              <el-option
                v-for="item in ywitem"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="is_left">
            <el-select
              v-model="form.is_left"
              clearable
              placeholder="收款情况"
              style="width: 130px"
            >
              <el-option
                v-for="item in shoukuan"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="check_status">
            <el-select
              v-model="form.check_status"
              placeholder="状态"
              style="width: 130px"
            >
              <el-option
                v-for="item in zhuaangtai"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submit">查 询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="warning" @click="resetForm('form')">
              重 置
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="3">
        <!-- 创建销售单活动 -->
        <!-- <el-button type="primary">添加客户预存款</el-button> -->
        <new-documents @getlist="getlist"></new-documents>
      </el-col>
    </el-row>
    <!-- table -->
    <el-row>
      <el-table
        v-loading="loading"
        stripe
        :data="datas"
        border
        style="width: 100%"
        show-summary
        :summary-method="summaryFunction"
        @selection-change="handlerSelection"
      >
        <el-table-column
          type="index"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          v-for="(item, index) in colem"
          :key="index"
          :align="item.align"
          :label="item.label"
          width=""
          :prop="item.prop"
        ></el-table-column>
        <el-table-column width="180" align="center" label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.check_status != 0"
              type="text"
              @click.native.prevent="linked(scope.row)"
            >
              查看
            </el-button>
            <el-button
              v-if="scope.row.check_status == 1"
              type="text"
              @click.native.prevent="handleEdit(scope.row)"
            >
              修改
            </el-button>
            <el-button
              v-if="scope.row.check_status != 0 && scope.row.check_status != 2"
              type="text"
              @click.native.prevent="onreder(scope.row)"
            >
              下单
            </el-button>
            <el-button
              v-if="scope.row.check_status == 1"
              type="text"
              @click.native.prevent="handleShouKuan(scope.row)"
            >
              收款
            </el-button>
            <el-button
              v-if="!scope.row.check_status"
              type="text"
              @click.native.prevent="Review(scope.row)"
            >
              审核
            </el-button>
            <el-button
              v-if="!scope.row.check_status"
              type="text"
              @click.native.prevent="handleDele(scope.row)"
            >
              作废
            </el-button>
            <el-button
              v-if="!scope.row.check_status || scope.row.check_status == 1"
              type="text"
              @click.native.prevent="handlePrint(scope.row)"
            >
              打印
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row type="flex" class="row-bg" justify="end">
      <el-pagination
        :current-page="form.pageNo"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </el-row>
    <el-row type="flex" class="row-bg">
      <el-button type="primary" @click="handlerBatchChangeStaff">
        更换业务员
      </el-button>
    </el-row>
    <link-details ref="link" @getlist="getlist"></link-details>
    <place-an-order ref="order" @getlist="getlist"></place-an-order>
    <review ref="review" @getlist="getlist"></review>
    <!--    打印预览组件-->
    <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      bill_type="YK"
      :data_id="data_id"
      :type="1"
    ></ruilang-display-dialog>
    <shou-kuan2 ref="shoukuan" @refresh="submit"></shou-kuan2>
    <ChangeStaff ref="changeStaff" @refresh="submit"></ChangeStaff>
  </div>
</template>

<script>
  import linkDetails from './components/components/likeDetails.vue'
  import PlaceAnOrder from './components/components/PlaceAnOrder.vue'
  import NewDocuments from './components/NewDocuments.vue'
  import { getClientSelect } from '@/api/saleOrder'
  import { postAction } from '@/api/Employee'
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'
  import shouKuan2 from './components/components/shouKuan'
  import Review from './components/Review.vue'
  import ChangeStaff from '@/views/project/finance/client/arrears/components/changeStaff.vue'
  import qs from 'qs'
  export default {
    name: 'Depositreceipt',
    components: {
      NewDocuments,
      linkDetails,
      PlaceAnOrder,
      Review,
      RuilangDisplayDialog,
      shouKuan2,
      ChangeStaff,
    },
    beforeRouteEnter(to, from, next) {
      console.log(to, '预存款')
      next((vm) => {
        console.log(vm)
        let querys = to.query
        if (querys.custId) vm.form.cust_id = querys.custId
        if (querys.is_left) vm.form.is_left = querys.is_left
        if (querys.name) vm.name = querys.name
      })
    },
    data() {
      return {
        data_id: '',
        zhuaangtai: [
          {
            id: 0,
            name: '未审核',
          },
          {
            id: 1,
            name: '已审核',
          },
          {
            id: 2,
            name: '已终止',
          },
          {
            id: 3,
            name: '已完成',
          },
          // {
          //   id: 4,
          //   name: '已作废',
          // },
        ],
        leixing: [
          {
            id: '1',
            name: '参加活动',
          },
          {
            id: '2',
            name: '业务员创建',
          },
        ],
        shoukuan: [
          {
            id: '1',
            name: '未结清',
          },
          {
            id: '2',
            name: '已结清',
          },
        ],
        options: [],
        ywitem: [],
        form: {
          deposit_id: '',
          is_create: '',
          cust_id: '',
          staff_id: '',
          is_left: '',
          check_status: '',
          pageNo: 1,
          pageSize: 10,
        },
        name: '',
        url: {
          yclist: '/promoteAdmin/deposit',
          ywylist: '/baseAdmin/common/staff-option',
          tablelist: '/promoteAdmin/deposit-order/index',
        },
        sum: [],
        loading: false,
        zonglist: {},
        datas: [],
        totalCount: 0,
        colem: [
          {
            align: 'center',
            label: '单号',
            width: '150',
            prop: 'bill_code',
          },
          {
            align: 'center',
            label: '终端名称',
            width: '150',
            prop: 'cust_name',
          },
          {
            align: 'center',
            label: '类型',
            width: '100',
            prop: 'deposit_name',
          },
          {
            align: 'center',
            label: '活动名称',
            width: '130',
            prop: 'name',
          },
          {
            align: 'center',
            label: '签单金额',
            width: '100',
            prop: 'total_amount',
          },
          {
            align: 'center',
            label: '赠送金额',
            width: '100',
            prop: 'gift_amount',
          },
          {
            align: 'center',
            label: '累计收款',
            width: '120',
            prop: 'receive_amount',
          },
          {
            align: 'center',
            label: '待收款',
            width: '120',
            prop: 'credit_amount',
          },
          {
            align: 'center',
            label: '可用余额',
            width: '120',
            prop: 'left_amount',
          },
          {
            align: 'center',
            label: '业务员',
            width: '100',
            prop: 'staff_user',
          },
          {
            align: 'center',
            label: '异常提醒',
            width: '80',
            prop: 'is_diff_text',
          },
          {
            align: 'center',
            label: '状态',
            width: '80',
            prop: 'check_status_text',
          },
        ],
        total: {},
      }
    },
    watch: {
      name(val) {
        if (val == '') {
          this.form.cust_id = ''
        }
      },
    },
    mounted() {
      this.handleritem()
      this.handlerywy()
      // this.handlerData()
      // 获取url参数
      console.log('获取url参数', this.$route.query.check_status)
      if (this.$route.query.check_status) {
        this.form.check_status = Number(this.$route.query.check_status)
        this.handlerData()
      } else {
        this.handlerData()
      }
    },
    methods: {
      /*
       * 重置按钮
       * */
      resetForm(formName) {
        this.$refs[formName].resetFields()
        this.name = ''
      },
      getlist() {
        this.submit()
      },
      //终止
      deleteRow() {},
      //审核
      Review(row) {
        this.$refs.review.showreview = true
        this.$refs.review.handlerdata(row.id)
      },
      //下单
      onreder(row) {
        this.$refs.order.innerVisible = true
        this.$refs.order.handlerData(row.id)
      },
      //查看
      linked(row) {
        this.$refs.link.outerVisible = true
        this.$refs.link.data_id = row.id
        this.$refs.link.isEdit = false
        this.$refs.link.handlerData(row.id)
      },
      // 修改
      handleEdit(row) {
        this.$refs.link.outerVisible = true
        this.$refs.link.data_id = row.id
        this.$refs.link.isEdit = true
        this.$refs.link.handlerData(row.id)
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.handlerData()
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.handlerData()
      },

      //haerd
      hanlerpageon(pageon) {
        this.form.pageNo = pageon
        this.handlerData()
      },
      handlerpagesize(pagesize) {
        this.form.pageSize = pagesize
        this.form.pageNo = 1
        this.handlerData()
      },
      submit() {
        this.form.pageNo = 1
        this.handlerData()
      },
      handlerData() {
        this.loading = true
        postAction(this.url.tablelist, this.form)
          .then((res) => {
            console.log(res, '列表')
            this.datas = res.data.list
            this.totalCount = res.totalCount
            this.total = res.data.total
            this.loading = false
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handlerywy() {
        postAction(this.url.ywylist, {})
          .then((res) => {
            console.log(res, '')
            this.ywitem = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handleritem() {
        postAction(this.url.yclist, { pageSize: -1, activity_status: 1 })
          .then((res) => {
            console.log(res, '')
            this.options = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      async fetchData(ky = '') {
        let { data, code, msg } = await getClientSelect({
          pageNo: 1,
          pageSize: -1,
          keyword: ky,
        })
        console.log('123')
        console.log(data)
        if (code == 200) {
          this.res = data
          return data
        } else {
          this.$message.error(msg)
        }
      },
      handlerSelection(val) {
        console.log(val, '选完后的')
        this.sum = val
      },
      handlerBatchChangeStaff() {
        if (this.sum.length > 0) {
          let ids = []
          this.sum.forEach((i) => {
            if (i.id) ids.push(i.id)
          })
          this.$refs.changeStaff.type = 1
          this.$refs.changeStaff.ids = ids
          this.$refs.changeStaff.showDialog = true
        } else {
          this.$message({
            message: '请勾选单据',
            type: 'info',
          })
        }
      },
      // 表格合计fun
      summaryFunction(param) {
        const { columns, data } = param
        // 需要合计的下标
        let i = [6, 7, 8]
        let sums = []
        // let val = data.map((item) => {
        //   console.log(item)
        // })
        columns.forEach((col, idx) => {
          i.forEach((n) => {
            if (idx == n) {
              console.log(col.property)
              const val = data.map((item) => Number(item[col.property]))
              console.log(val)
              if (!val.every((value) => isNaN(value))) {
                const a = val.reduce((total, num) => {
                  if (!isNaN(total)) {
                    return total + num
                  }
                })
                sums[n] = a.toFixed(2) + ' 元'
              }
            }
          })
          if (idx == 0) {
            sums[idx] = '合计'
          }
        })
        sums[10] = this.total.left_amount
        sums[9] = this.total.credit_amount
        console.log('sss', sums)
        return sums
      },
      handleSelect(val) {
        console.log(val, this.form.cust_id, '')
        this.form.cust_id = val.id
      },
      querySearchAsync(queryString, callback) {
        if (queryString == '') {
          cb([])
        } else {
          this.fetchData(queryString).then((val) => {
            console.log(val)
            callback(val)
          })
        }
      },
      handleDele(row) {
        // /promoteAdmin/deposit-order/order-cancle
        this.$confirm('确定要作废这条数据吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            postAction('/promoteAdmin/deposit-order/order-cancle', {
              id: row.id,
            }).then((r) => {
              this.handlerData()
              this.$message({
                type: 'success',
                message: '作废成功!',
              })
            })
          })
          .catch(() => {})
      },
      handlePrint(row) {
        this.data_id = row.id
        this.$refs['RuilangDisplayDialog'].batch = false
        this.$refs['RuilangDisplayDialog'].dialogFormVisible = true
      },
      handleShouKuan(row) {
        console.log(row)
        this.$refs.shoukuan.type = 1
        this.$refs.shoukuan.form = row
        this.$refs.shoukuan.showDialog = true
      },
    },
  }
</script>

<style>
  .padding {
    padding: 20px;
  }
</style>
