var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        { attrs: { stripe: "", data: _vm.table } },
        [
          _c(
            "el-table-column",
            { attrs: { label: "协议赠品", width: "auto", align: "center" } },
            [_c("giveaway-ding", { ref: "ding", on: { xieyi: _vm.datalist } })],
            1
          ),
          _c(
            "el-table-column",
            { attrs: { label: "指定商品", width: "auto", align: "center" } },
            [_c("limited", { ref: "limit", on: { xianding: _vm.xianding } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }