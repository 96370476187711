<!--
 * @Author: 候怀烨
 * @Date: 2020-12-02 11:03:00
 * @LastEditTime: 2020-12-23 10:44:40
 * @LastEditors: Please set LastEditors
 * @Description: 限定商品
 * @FilePath: \sd-vue-admin\src\views\project\movablepin\predeposit\depositactivity\components\Giveaway.vue
-->
<template>
  <div>
    <span style="padding: 0px 10px">{{ addgoods.length }}</span>
    <i class="el-icon-edit-outline" @click="dialogVisible = true"></i>
    <el-dialog
      :modal="false"
      title="限定商品"
      :visible.sync="dialogVisible"
      width="60%"
    >
      <div>
        <el-row type="flex" class="row-bg" justify="space-between">
          <span>限定品牌：</span>
          <add-brand ref="tree" @treelit="treelit"></add-brand>
        </el-row>
        <el-row :gutter="20" style="padding: 10px 0px">
          <el-col v-for="(item, indexd) in treelist" :key="indexd" :span="6">
            <el-button class="el-icon-close" @click="deletetree(index)">
              {{ item.brand_name }}
            </el-button>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-between">
          <span>限定商品：</span>
          <adding-goods ref="goods" @addgoos="addgoos"></adding-goods>
        </el-row>
        <!-- table表格 -->
        <el-row>
          <el-table stripe :data="addgoods" max-height="300">
            <el-table-column type="index" width="50"></el-table-column>
            <el-table-column
              v-for="(list, indexs) in comle"
              :key="indexs"
              :prop="list.prop"
              :label="list.label"
              :align="list.align"
              width=""
            >
              <template v-if="list.label == '下单价'" #default="{ row }">
                <el-input v-model="row.sell_price"></el-input>
              </template>
              <template v-else #default="{ row }">
                {{ row[list.prop] }}
              </template>
            </el-table-column>
            <el-table-column label="操作" prop="" align="center" width="auto">
              <template #default="{ $index, row }">
                <el-button type="text" @click="deletetab($index, row)">
                  删 除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="xianding">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import AddBrand from './AddBrand.vue'
  import AddingGoods from './AddingGoods.vue'
  export default {
    components: {
      AddBrand,
      AddingGoods,
    },
    props: {
      index: {
        type: Number,
        default() {
          return 0
        },
      },
    },
    data() {
      return {
        dialogVisible: false,
        addgoods: [],
        treelist: [],
        comle: [
          {
            prop: 'goods_name',
            label: '商品名称',
            align: 'center',
            width: 'auto',
          },
          {
            prop: 'specs',
            label: '规格',
            align: 'center',
            width: 'auto',
          },
          {
            prop: 'goods_unit',
            label: '单位',
            align: 'center',
            width: 'auto',
          },
          {
            prop: 'sell_price',
            label: '下单价',
            align: 'center',
            width: 'auto',
          },
        ],
      }
    },
    methods: {
      kong() {
        this.addgoods = []
        this.treelist = []
        this.$refs.tree.kong()
        this.$refs.goods.kong()
      },
      xianding() {
        this.$emit('xianding', this.addgoods, this.treelist)
        this.dialogVisible = false
      },
      deletetab(index, row) {
        this.addgoods.splice(index, 1)
        this.$refs.goods.select(row)
      },
      //获取商品
      addgoos(vol) {
        this.addgoods = vol
      },
      // 改变品牌选中状态
      deletetree(index) {
        console.log(index, '')
        this.$refs.tree.handlerchange(this.treelist[index])
        this.treelist.splice(index, 1)
      },
      // 获取商品列表
      treelit(row) {
        this.treelist = row
      },
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then((_) => {
            done()
          })
          .catch((_) => {})
      },
    },
  }
</script>

<style></style>
